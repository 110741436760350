*{
  margin: 0;
  padding: 0;
  font-family: 'Manrope', sans-serif;
}

/* LOADER */
#loadMe{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#loaderImg{
  width: 10rem;
  animation: rockHands .2s infinite alternate;;
} 

@keyframes rockHands {
  from{
    width: 10rem;
  }
  to{
    width: 9.3rem;
  }
} 

/* SCROLL BAR */
/* width */
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #0B0D1C;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--lightGrey);
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--grey);
}




body{
  background-color: #0B0D1C;
  overflow-x: hidden;
}

/* SYSTEM DESIGN */
#h1{
  font-weight: 800;
  font-size: 100px;
  line-height: 137px;

  letter-spacing: 1px;
}
#h2{
  font-weight: bold;
  font-size: 70px;
  line-height: 96px;
  letter-spacing: 3px;
}
#h3{
  font-weight: normal;
  font-size: 48px;
  line-height: 68px;
}
#title{
  font-weight: bold;
  font-size: 30px;
}
#h4{
  font-weight: 600;
  font-size: 1.2rem;
  text-transform: capitalize    
}
#text{
  font-weight: normal;
  font-size: 23px;
  line-height: 31px;
  color: var(--lightGrey);
}
a{
  text-decoration: none;
  cursor: pointer;
}

/* READ MORE */
.readMore{
  cursor: pointer;
  background-color: var(--purple);
  border-radius: 90px;

  padding: 10px 15px;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 17px;
  line-height: 30px;
  margin-top:2rem;
}
.readMore a{
  text-decoration: none;
  color: var(--offWhite);
}
.readMore:hover{
  transform: translateY(-2px);
  background-color: var(--lightPurple);

}

/* IFRAME */
iframe:hover{
  cursor: pointer;
}

button{
  border: none;
  position: relative;
  overflow: hidden;
  transition: background 400ms;
  color: #fff;
  background-color: #6200ee;
  padding: 1rem 2rem;
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
  outline: 0;
  border: 0;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3); /* black with 30% opacity */
  cursor: pointer;
}

span.ripple {
  position: absolute; /* The absolute position we mentioned earlier */
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
  background-color: rgba(255, 255, 255, 0.7);
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}


/* NAV BAR */
nav{
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-around;
  overflow: hidden;

  top: 3vh;
  margin-left: 10vh;
  height: 10%;
  width: 32%;
  border-radius: 60px;
  background-color: var(--darkGrey);
  z-index:10;
}
#navImg{
  display: flex;
  flex-wrap: wrap;
  width: 10%;
  margin-left: 5%;
}
nav div{
  display: flex;
  align-items: center;
  width: 65%;
  justify-content: space-around;
  margin: 5%;
}
nav div a{
  text-decoration: none;
  font-size: 22px;
  color: var(--offWhite);
}
.navLinks:hover{
  color: var(--lightGrey);
  transform: translateY(-2px);
}
nav div button{
  background-color: var(--grey);
  border-radius: 24px;
  width: auto;
  padding: 10px 15px ;
  border: none;
}
nav div button:hover{
  background-color: var(--lightGrey);
  border-radius: 24px;
  border:none;  
}


/* SOCIAL MEDIA */
#socialMedia{
  position: fixed;
  display: flex;
  justify-content: space-between;
  bottom: 2vh;
  right: 4vh; 
  width: 16vw;
  z-index:100;
}
#socialMedia img{
  width: 3rem;
}
#socialMedia img:hover{
  transform: translateY(-2px);
}


/* TEXT LOOP */
.loopWrapper{
  width: 100vw;
  z-index: 1;
}
.loopWrapper #h1{  
  color: transparent;
  white-space: nowrap;
  -webkit-text-stroke: .1px var(--offWhite);
  animation: infiniteText 5s linear infinite; 
  
}
@keyframes infiniteText {
  0%{transform:translateX(0)}
  100%{transform: translateX(-70%);}
}


/* SECTION */
section{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
}


/* LANDING */
#landing{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
#landingCont{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 90%;
  height: 100%;
}
#landingText{
  width: 50%;
  color: var(--offWhite);
}

#landingText .name b{
  color: var(--purple);
  font-weight: 900;
}
#landingText .name{
  margin: 0 auto;
  white-space:nowrap;
  overflow: hidden;
  text-align: center;
}

/* Animation */
.anim-typewriter{
animation: typewriter 4s steps(44) 1s 1 normal both,
           blinkTextCursor 500ms steps(44) infinite normal;
}
@keyframes typewriter{
  from{width: 0;}
  to{width: 7em;}
}

@keyframes blinkTextCursor{
from{border-right-color: rgba(255,255,255,.75);}
to{border-right-color: transparent;}
}

#landing iframe{
  position: relative;
  width: 35vw;
  height: 55vh;
}


/* MOI */
#moi{
  display: flex;
  justify-content: center;
}
#moiCont{
  display: flex;
  align-items: center;
  justify-content: space-around;
}
#moi #h3{
  color: var(--lightGrey);
  width: 50%;
}
#moi #h3 a{
  color: var(--purple);
  font-weight: bold;
  margin: 5px;
}
#moi #h3 b{
  color: var(--lightPurple);
  margin: 5px;
}

#moiCont img{
  width: 40%;
}



/* EXPERIENCE */
#experience{
  margin-top: 10rem;
}
#expCont{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: space-around;
}
.expBox{
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 95%;
  height: 85vh;
  box-sizing: border-box;
}
.expText{
  color: var(--offWhite);
  width: 45%;
}
.expText div{
  margin-top: 20px;
}

mark{
  background-color: var(--grey);
  color: var(--offWhite);
}

.expBox iframe{
  position: relative;
  width: 45%;
  overflow: hidden;
}
.expBox img{
  position: relative;
  width: 30%;
  height: auto;
}


/* EXP PAGE */
#expMain{
  margin-top: 8rem;
}
.expPage{
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: 5rem;
  gap: 1rem;
}
.expPage #h1{ 
  font-weight: 600;
}
.expPage #text{
  width: 70%;
}
.expPageDesc{
  display: flex;
  flex-direction: row;
  justify-content: center;
 
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 5rem;
  margin-top: 4rem;
}
.expPageDescCont{
  width: 85%;
  display: flex;
  justify-content: space-around;
}
.expPageDescBox{
  display: flex;
  flex-direction: column;
  width: 18rem;
  gap: .5rem;
}
.expPageDescBox #h4{
  color: var(--offWhite);
}
.info-section-Info img{
  width: 3rem;
}

.expAd{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.expAd iframe{
  width: 60rem;
  height: 33.8rem;
}


b{
  color:var(--lightPurple);
}
.expPageContent{
  display: flex;
  justify-content: center;
}
.expPageContentCont{
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: space-around;
  width: 100%;
  gap: 5rem;
}
.expPageContentBox{
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 90%;
  height: 38rem;
  box-sizing: border-box;
}
.expPageContentBox #xmas2020{
  height: 40rem;
  width: auto;
}
.expPageContentSec {
  display: flex;
  flex-direction: column;
  width: 35rem;
  gap: 1rem;
}
.expPageContentSec #h2{
  color: var(--offWhite);
}

.expPageContentSec ul{
  margin-left: 2.5rem;
}
.expPageContentSec ol{
  margin-left: 2.5rem;
}
.expPageContentSec a{
  color: var(--purple)
}
.expPageContentSec p{
  margin-bottom: 1rem;
}

/*Footer*/
#footer{
  width: 100%;
  position: relative;
  bottom: 0;
  color: var(--offWhite);

}
#footerTitle{
  display: flex;
  flex-direction: column;
  margin: 3rem;
  gap: .5rem;
}

#footerBottom #text{
  text-align: center;
  margin-top: 1rem;
  font-size: 15px;
}




@media (max-width: 1200px){
  nav{
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px #0000007f;
    width: 80%;
  }
  #socialMedia{
    justify-content: center;
    width: 100%;
    right: 0;
    bottom: 0;
  }
  #socialMedia img{
    width: 4rem;
    margin-inline: 1rem;
  }
  /* READ MORE */
  .readMore a{
    color: var(--purple);
  }
  .readMore{
    color: var(--purple);
    cursor: pointer;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    padding:0;
    margin-top:1rem;
  }

  .readMore:hover{
    color: var(--purple);
    cursor: pointer;
    background-color: transparent;
    border:0;
  }
  /* LANDING */
  #landingCont{
    width: 100%;
}
  #landing iframe{
    display: none;
  }
  #landingText{
    width: 65%;
  }

  /* MOI */
  #moiCont{
    flex-direction: column;
  }
  #moi #h3{
    margin-top: 4rem;
    width: 75%;
    text-align: center;
  }
  #moiCont img{
    width: 60%;
  }

  /* EXPERIENCE */
  #experience{
    margin-top: 5rem;
  }
  #expCont{
    flex-direction: column;
  }
  .expBox{
    flex-direction: column;
    margin-top: 3rem;
    width: 75%;
    height: 35rem;
    border-radius: 30px;
    justify-content: space-around;
    background-color: var(--darkGrey);
    overflow: hidden;
  }
  .expText{
    text-align: center;
    width: 80%;
    margin-bottom: 1rem;
  }
  .expText #h2{
    font-size: 60px;
  }
  .expText #h4{
    display: none;
  }
  .expText #title{
    display: none;
  }
  .expBox iframe{
    display: none;
  }
  .expBox img{
    display: none;
  }

  /*Footer*/
  #footerTitle{
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 3rem;
    margin-bottom: 6rem;
  }
  #footerBottom #text{
    display: none;
  }

  /* EXP PAGE */
  #backNav{
    width: 80%;
  }
}

@media (max-width: 975px){
/* EXP PAGE */
.expPageDesc{
  display: none;
}
.expAd iframe{
  width: 40rem;
  height: 25rem;
}
.expPageContentBox{
  flex-direction: column;
  width: 100vw;
  align-items: center;
  height: auto;
}
.expPageContentBox #xmas2020{
  height: 40rem;
  width: auto;
}
.expPageContentSec {
  align-items: center;
  text-align: center;
  width: 80%;
  margin-bottom: 2rem;

}
.expPageContentSec img{
  width: 30rem;
}
.expPageContentSec ul{
  text-align: left;
}
.expPageContentSec ol{
  text-align: left;
}
}

@media (max-width: 900px){
  #h1{
    font-size: 65px;
    line-height: normal;
  }
  #h2{
    font-size: 40px;
    line-height: normal;
  }

  nav{
    display: none;
  }

  #backNav{
    display:block;
  }
  #backNav img{
    display: none;
  }
  #backNav button{
    display: none;
  }
  #backNav .navLinks{
    display: block;
    font-size: 30px;
  }

  #landingText{
    text-align: center;
    width: 90%;
  }

  #landingText .name{
    animation: none;
    overflow:visible; 
    border-right: none;
    white-space:normal; 
  }

  #moiCont img{
    width: 90%;
  }

  #moiCont #h3{
    font-size: 35px;
    line-height: normal;
    margin-top: 1rem;
  }
  #footer{
    text-align: center;
  }

  .expPage{
    margin-bottom: 2rem;
  }

  .expPageContentSec img{
    width: 90%;
  }


}

@media (max-width: 650px){
  .expText #h2{
    font-size: 35px;
  }
}







:root{
  --purple: #904BFF;
  --lightPurple: #BC9AFF;
  --lightGrey: #838790; 
  --grey: #484848;
  --darkGrey: #252525;
  --offWhite: #f1f1f1;
}


.title-ccs{
  color: #0E4F1C;
}

.title-marketangelo{
  color: #01ACC0;
}

.title-swim{
  color: #00ACDB;
}


.title-shad{
  color: #00A7E1;
}

.title-beadsforseeds{
  color: #187a2e;
}

.title-council{
  color: #5B1E30;
}

.title-retter{
  color:rgb(182, 181, 181);
}

.title-sea{
  color: #03257D;
}